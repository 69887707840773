import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import SunIcon from '@material-ui/icons/Brightness7';
import MoonIcon from '@material-ui/icons/Brightness2';

import settings from '../settings';
import { eventTypes, emitEvent } from '../events';

import logoSign from './../assets/logo_sign.png';
import logoText from './../assets/logo_text.png';

import MenuIcon from '@material-ui/icons/Menu';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';

import MediaQuery from 'react-responsive';

const styles = (theme) => ({
  toolbar: {
    margin: '0.25rem 0.5rem 0.25rem 1rem',
  },
  logoContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#fff',
  },
  logoSign: {
    height: '36px',
  },
  logoText: {
    marginLeft: '15px',
    marginTop: '-2px',
    marginRight: '15px',
    height: '36px',
  },
  premiumBadge: {
    display: 'inline-block',
    margin: theme.spacing(2),
    padding: theme.spacing(2, 0),
  },
  navigationToolbar: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: '12rem',
  },
  circularProgress: {
    color: 'white',
  },
  modeButton: {
    textTransform: 'none',
  },
  mobileAppLinkContent: {
    display: 'flex', 
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  },
  mobileAppLinkIcon: {
    marginRight: 5,
  },
  mobileAppLinkBadge: {
    backgroundColor: "red", 
    borderRadius: 5, 
    paddingLeft: 5, 
    paddingRight: 5, 
    paddingTop: 2, 
    paddingBottom: 2,
    position: 'absolute',
    right: -10,
    fontSize: 10,
    fontWeight: 'bold',
    color: 'white',
  },
});

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptionFetched: false,
      subscriptionCurrentPeriodEndFetched: false,
      menuElem: null,
      isPremiumWeb: undefined,
      isPremiumMobile: undefined,
    };
  }

  componentDidMount() {
    this.fetchSubscription();
    this.fetchSubscriptionCurrentPeriodEnd();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { subscriptionFetched, subscriptionCurrentPeriodEndFetched } = this.state;
    if (!nextProps.isSignedIn) {
      this.setState({
        subscriptionFetched: false,
        subscriptionCurrentPeriodEndFetched: false,
      });
    }
    if (!subscriptionFetched && nextProps.isSignedIn) {
      this.fetchSubscription();
    }
    if (!subscriptionCurrentPeriodEndFetched && nextProps.isSignedIn) {
      this.fetchSubscriptionCurrentPeriodEnd();
    }
  }

  fetchSubscriptionCurrentPeriodEnd = () => {
    const { getSubscriptionCurrentPeriodEnd } = this.props;
    getSubscriptionCurrentPeriodEnd(
      (currentPeriodEnd) => {
        const now = Date.now() / 1000;
        const isSubscriptionActive = currentPeriodEnd && currentPeriodEnd > now;
        this.setState({
          isPremiumMobile: isSubscriptionActive,
          subscriptionCurrentPeriodEndFetched: true,
        });
      },
      () => {
        this.setState({
          isPremiumMobile: false,
          subscriptionCurrentPeriodEndFetched: true,
        });
      }
    );
  }

  fetchSubscription = () => {
    const { getSubscription } = this.props;

    getSubscription(
      (subscription) => {
        this.setState({
          isPremiumWeb: subscription.ended_at === null,
          subscriptionFetched: true,
        });
      },
      () => {
        this.setState({
          isPremiumWeb: false,
          subscriptionFetched: true,
        });
      }
    );
  };

  openMenu = (event) => {
    this.setState({ menuElem: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ menuElem: null });
  };

  handleSettingsClick = () => {
    this.closeMenu();
    this.props.onSettingsClick();
  };

  handleTutorialsClick = () => {
    this.closeMenu();
    this.props.onTutorialsClick();
  };

  handleSignOutClick = () => {
    this.closeMenu();
    this.props.onSignOutClick();
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { isPerformingAuthAction } = this.props;

    // Events
    const { onUpgradeClick } = this.props;
    const { onEmailSupportClick, onChatClick } = this.props;
    const { theme, onThemeToggle } = this.props;

    const { menuElem, isPremiumWeb, isPremiumMobile } = this.state;
    const isPremium = (isPremiumWeb || isPremiumMobile) 
      ? true : 
      (
        (isPremiumWeb === undefined || isPremiumMobile === undefined) 
        ? undefined
        : false
      );

    return (
      <AppBar color="primary" position="fixed">
        <Toolbar variant="dense" disableGutters className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <Link to={settings.routes.HOME} className={classes.logoWrapper}>
              <img src={logoSign} className={classes.logoSign} alt="chessvision.ai" />
              {/* make sure that all other elements fit on smaller screens */}
              <MediaQuery minWidth={400}>
                <img src={logoText} className={classes.logoText} alt="chessvision.ai" />
              </MediaQuery>
            </Link>
          </div>
          { isPremium === undefined &&
            <CircularProgress className={classes.circularProgress} size="1rem" />
          }
          { isPremium === true &&
            <Typography variant="button" color="secondary">MEMBER</Typography>
          }
          { isPremium === false &&
            <Button
              color="secondary"
              variant="contained"
              disabled={isPerformingAuthAction}
              onClick={() => {
                emitEvent(eventTypes.subscriptionPlansDialogOpenAppBar);
                onUpgradeClick();
              }}
            >
              Upgrade
            </Button>
          }
          <React.Fragment>
            <IconButton color="inherit" disabled={isPerformingAuthAction} onClick={this.openMenu}>
              <MenuIcon />
            </IconButton>

            <Menu
              anchorEl={menuElem}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              open={Boolean(menuElem)}
              onClose={this.closeMenu}
            >
              <MenuItem component={Link} to={settings.routes.HOME} disabled={isPerformingAuthAction} onClick={this.closeMenu}>Reader</MenuItem>
              <MenuItem component={Link} to={settings.routes.DOCUMENTS} disabled={isPerformingAuthAction} onClick={this.closeMenu}>My Documents</MenuItem>
              <MenuItem component={Link} to={settings.routes.SEARCH} disabled={isPerformingAuthAction} onClick={this.closeMenu}>Search Diagrams</MenuItem>
              {/* new method with /settings page */}
              <MenuItem component={Link} to={settings.routes.SETTINGS} disabled={isPerformingAuthAction} onClick={this.closeMenu}>Settings</MenuItem>
              {/* old method of having settings dialog 
              <MenuItem disabled={isPerformingAuthAction} onClick={this.handleSettingsClick}>Settings</MenuItem>
              */}
              <MenuItem 
                component="a" 
                href={settings.values.appStoreUrl}
                onClick={() => { emitEvent(eventTypes.menuIosClick)}}
                icon="apple"
                target="_blank"
              >
                <div className={classes.mobileAppLinkContent}>
                  <AppleIcon fontSize="small" className={classes.mobileAppLinkIcon} />
                  iOS App
                  <span className={classes.mobileAppLinkBadge}>NEW</span>
                </div>
              </MenuItem>
              <MenuItem 
                component="a" 
                href={settings.values.playStoreUrl}
                onClick={() => { emitEvent(eventTypes.menuAndroidClick)}}
                target="_blank"
              >
                <div className={classes.mobileAppLinkContent}>
                  <AndroidIcon fontSize="small" className={classes.mobileAppLinkIcon} />
                  Android App
                  <span className={classes.mobileAppLinkBadge}>NEW</span>
                </div>
              </MenuItem>
              <MenuItem disabled={isPerformingAuthAction} onClick={this.handleTutorialsClick}>Video Tutorials</MenuItem>
              <MenuItem component={Link} to={settings.routes.FAQ} disabled={isPerformingAuthAction} onClick={this.closeMenu}>FAQ</MenuItem>
              {/*
              <MenuItem
                component="a"
                href={`mailto:${settings.values.supportEmail}?subject=Chessvision.ai eBook Reader general support request`}
                rel="noopener noreferrer"
                target="_blank"
                disabled={isPerformingAuthAction}
                onClick={this.closeMenu}
              >
                Email Support
              </MenuItem>
              */}
              <MenuItem
                component="a"
                disabled={isPerformingAuthAction}
                onClick={() => { onEmailSupportClick(); this.closeMenu()}}
              >
                Email Support
              </MenuItem>
              <MenuItem
                component="a"
                disabled={isPerformingAuthAction}
                onClick={() => { onChatClick(); this.closeMenu()}}
              >
                Chat Support
              </MenuItem>
              <MenuItem
                component="button"
                onClick={onThemeToggle}
              >
                <Button
                  className={classes.modeButton}
                  startIcon={theme === 'light' ? <MoonIcon /> : <SunIcon />}
                >
                  { theme === 'light' ? 'Dark Mode (beta)' : 'Light Mode'}
                </Button>
              </MenuItem>
              <MenuItem disabled={isPerformingAuthAction} onClick={this.handleSignOutClick}>Logout</MenuItem>
            </Menu>
          </React.Fragment>
        </Toolbar>
      </AppBar>
    );
  }
}

Bar.propTypes = {
  classes: PropTypes.object.isRequired,
  isPerformingAuthAction: PropTypes.bool.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onTutorialsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
  getSubscriptionCurrentPeriodEnd: PropTypes.func.isRequired,
  onUpgradeClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Bar);
