import React, { Component} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';


const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 10,
  },
  colorPrimary: {
    color: '#fff',
    backgroundColor: '#000',
  },
  colorSecondary: {
    color: '#fff',
    backgroundColor: '#ccc',
  }
}))(Badge);

class RelatedGames extends Component {

  state = {
    games: undefined,
  };

  componentDidMount() {
    const { fen } = this.props;
    this.fetchRelatedGames(fen);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fen !== this.props.fen) {
      this.fetchRelatedGames(nextProps.fen);
    }
  }

  fetchRelatedGames = (fen) => {
    this.setState({
      games: undefined,
    });
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/search_games?fen=${fen}`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
       })
      });
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.json())
    })
    .then(data => {
      this.setState({
        games: data,
      });
    })
    .catch(e => {
      this.setState({
        games: { 'chess.com': { num_games: 0 } },
      });
    })
  }

  renderButton() {
    const { games } = this.state;
    if (games === undefined) {
      return (
        <CircularProgress size={16} color="primary" />
      )
    }
    const { num_games: numGames, games_url: gamesUrl, error } = games['chess.com'];

    const disabled = numGames === 0;

    return (
      <Tooltip title="Games with this position">
        <IconButton
          target="_blank"
          href={gamesUrl}
          disableRipple
          disabled={disabled}
          style={{ backgroundColor: 'transparent', padding: 0 }}
        >
          <StyledBadge badgeContent={error ? 25 : numGames} max={20} showZero color={disabled ? 'secondary' : 'primary'}>
            <SearchIcon style={{ color: disabled ? '#ccc' : '#000', fontSize: 32 }} />
          </StyledBadge>
        </IconButton>
      </Tooltip>
    );
  }

  render() {
    return (
      <div style={{ minWidth: 50, textAlign: 'center', marginRight: '0.25rem' }}>
        {this.renderButton()}
      </div>
    );
  }
}

export default RelatedGames;
