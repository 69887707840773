import settings from './settings';

const eventTypes = {
  // Landing.js
  signUpGoogle: {
    name: 'sign_up',
    method: 'google',
  },
  signInGoogle: {
    name: 'sign_in',
    method: 'google',
  },

  signUpEmail: {
    name: 'sign_up',
    method: 'email',
  },
  signInEmail: {
    name: 'sign_in',
    method: 'email',
  },

  iosCTAClick: {
    name: 'mobile_cta_click',
    store: 'ios',
  },
  androidCTAClick: {
    name: 'mobile_cta_click',
    store: 'android',
  },

  // App.js
  signOut: {
    name: 'sign_out',
  },

  // Menu
  menuIosClick: {
    name: 'menu_mobile_cta_click',
    store: 'ios',
  },
  menuAndroidClick: {
    name: 'menu_mobile_cta_click',
    store: 'android',
  },

  // Reader.js
  documentOpenDisk: {
    name: 'document_open',
    'storage_type': 'disk',
  },
  documentOpenCloud: {
    name: 'document_open',
    'storage_type': 'cloud',
  },
  processingDialogOpen: {
    name: 'processing_dialog_open',
  },
  processingDialogCancel: {
    name: 'processing_dialog_cancel',
  },
  documentAnalysisStart: {
    name: 'document_analysis_start'
  },
  documentConversionStart: {
    name: 'document_conversion_start'
  },
  diagramLoadSuccess: {
    name: 'diagram_load_success',
  },
  diagramLoadForbidden: {
    name: 'diagram_load_forbidden',
  },
  diagramLoadNotFound: {
    name: 'diagram_load_not_found',
  },
  subscriptionPlansDialogOpenStatusBar: {
    name: 'subscription_plans_dialog_open',
    'entrypoint': 'status_bar',
  },
  videoTutorialsDialogOpenMenu: {
    name: 'video_tutorials_dialog_open',
    'entrypoint': 'menu',
  },
  videoTutorialsDialogOpenTiles: {
    name: 'video_tutorials_dialog_open',
    'entrypoint': 'tiles',
  },
  documentsOpenTiles: {
    name: 'my_documents_open',
    'entrypoint': 'tiles',
  },
  diagramSearchOpenTiles: {
    name: 'diagram_search_open',
    'entrypoint': 'tiles',
  },
  samplePdfsOpenTiles: {
    name: 'sample_pdfs_open',
    'entrypoint': 'tiles',
  },
  relatedVideosOpen: {
    name: 'related_videos_open',
  },

  studyCreatorExportOpen: {
    name: 'study_creator_export_open',
  },

  studyCreatorEditOpen: {
    name: 'study_creator_edit_open',
  },

  unsupportedFileFormatOpen: {
    name: 'unsupported_file_format_open',
  },
  conversionDialogOpen: {
    name: 'conversion_dialog_open',
  },

  // BoardAnalysis.js
  studyCreatorOpen: {
    name: 'study_creator_open',
  },
  diagramCommentOpen: {
    name: 'diagram_comment_open',
  },
  // Chessable courses
  chessableCoursesShow: {
    name: 'chessable_courses_show',
  },
  chessableCoursesClick: {
    name: 'chessable_courses_click',
  },

  // App/Bar.js
  subscriptionPlansDialogOpenAppBar: {
    name: 'subscription_plans_dialog_open',
    'entrypoint': 'app_bar',
  },

  // App.js
  subscriptionPlansDialogCancel: {
    name: 'subscription_plans_dialog_cancel',
  },

  // CheckoutButton.js
  checkoutBegin: {
    name: 'checkout_begin',
  },

  // CheckoutCanceledContent.js
  checkoutCancel: {
    name: 'checkout_cancel',
  },

  // CheckoutSuccessfulContent.js
  checkoutSuccess: {
    name: 'checkout_success',
  },

  // CustomerPortalButton.js
  customerPortalOpen: {
    name: 'customer_portal_open',
  },

  // 3rd party cookies BoardAnalysis.js
  closeThirdPartyCookiesDialog: {
    name: 'close-3rd-party-cookies-dialog',
  },
  doNotShowThirdPartyCookiesDialog: {
    name: 'do-not-show-3rd-party-cookies-dialog',
  },

  // Search.js
  searchPerform: {
    name: 'search_perform',
  },
  searchResultOpen: {
    name: 'search_result_open',
  },
  subscriptionPlansDialogOpenDiagramSearch: {
    name: 'subscription_plans_dialog_open',
    'entrypoint': 'diagram_search',
  },

  // Say Chess Newsletter

  sayChessTilesGetBookClick: {
    name: 'say_chess_get_book_click',
    'entrypoint': 'tiles',
  },
  sayChessTilesCTAClick: {
    name: 'say_chess_cta_click',
    'entrypoint': 'tiles',
  },
  sayChessSnackbarCTAClick: {
    name: 'say_chess_cta_click',
    'entrypoint': 'snackbar',
  },
  sayChessTilesAboutClick: {
    name: 'say_chess_about_click',
    'entrypoint': 'tiles',
  },

};

const emitEvent = (eventType, options = {}) => {
  if (!settings.useAnalytics) {
    console.log(`---EMIT EVENT---`);
    console.log(`eventType=${JSON.stringify(eventType)}`);
    console.log(`options=${JSON.stringify(options)}`);
    console.log(`----------------`);
    return;
  }
  const { name, ...params } = eventType;

  // window.analytics is binded to Segment in public/index.html
  window.analytics.track(name, { ...params, ...options });
}

const identifyUser = user => {
  if (!settings.useAnalytics) {
    return;
  }
  const payload = { email: user.email}
  if (user.displayName) {
    payload.name = user.displayName;
  }
  window.analytics.identify(user.uid, payload);
}

export {
  eventTypes,
  emitEvent,
  identifyUser,
};
