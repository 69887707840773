/* eslint-disable no-unused-vars */

import React from 'react';

import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import gray from '@material-ui/core/colors/grey';
import blueGray from '@material-ui/core/colors/blueGrey';

/* eslint-enable no-unused-vars */

const IOS_APP_ID = '6599857782'; 
const ANDROID_APP_ID = 'ai.chessvision.reader';

const chessvisionPrimaryColor = {
  main: '#1d0954',
  light: '#2E186D',
};

const chessvisionSecondaryColor = deepOrange;

const settings = {
  useAnalytics: process.env.REACT_APP_USE_ANALYTICS === 'true',
  title: 'Chessvision.ai eBook Reader',
  rootApiUrl: process.env.REACT_APP_ROOT_API_URL,

  theme: {
    primaryColor: chessvisionPrimaryColor,
    secondaryColor: chessvisionSecondaryColor,
    backgroundColor: gray,
    documentWidth: 800,
  },

  routes: {
    HOME: '/',
    TERMS: '/terms',
    DOCUMENTS: '/documents',
    SEARCH: '/search',
    SETTINGS: '/settings',
    FAQ: '/faq',
    RESOURCES: '/resources',
    CHECKOUT_SUBSCRIPTION_SUCCESSFUL: '/checkout/subscription/successful',
    CHECKOUT_SUBSCRIPTION_CANCELED: '/checkout/subscription/canceled',
    CHECKOUT_PURCHASE_SUCCESSFUL: '/checkout/purchase/successful',
    CHECKOUT_PURCHASE_CANCELED: '/checkout/purchase/canceled',
  },

  credentials: {
    firebase: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    }
  },

  pricing: {
    monthly: {
      plan: 'monthly',
      price: {
        dollars: 5,
        cents: 99,
      },
    },
    yearly: {
      plan: 'yearly',
      price: {
        dollars: 45,
        cents: 99,
      },
    }
  },

  values: {
    chessvisionHomepage: 'https://chessvision.ai',
    iosUrl: '',
    appStoreUrl: `https://apps.apple.com/us/app/id${IOS_APP_ID}`,
    playStoreUrl: `https://play.google.com/store/apps/details?id=${ANDROID_APP_ID}`,
    analysisPoolInterval: 10000, // in milliseconds
    conversionPoolInterval: 10000, // in milliseconds
    supportEmail: 'pawel@chessvision.ai',
    maxFileSize: 50 * 1024 * 1024, // in bytes
    requireVerifiedEmail: false,
    showRerunButtons: false,
    showReportAnalysisIssue: false,
    oneTimePurchaseEnabled: false,
    maxMobileWidth: 767,
    newTabRemoteFileKey: 'new-tab-remote-file',
    studyCreatorKey: 'study-creator',
    studyCreatorOpenKey: 'study-creator-open',
    CHESS_COM_REF_ID: "23962172",
    supportedFileFormats: ['PDF', 'ePub', 'DjVu'],
    sayChessBannerHiddenKey: 'saychess-banner-hidden',
  },

  messages: {
    reader: {
      noFileOpened: 'Open a PDF, ePub, or DjVu file and get started',
      recognizingFile: 'Recognizing file...',
      analyzingFile: 'File in analysis queue. It may take several minutes depending on the file',
      convertingFile: 'File in conversion queue. It may take several minutes depending on the file',
      fileReady: 'Double-click a diagram to open it',
      fileNotYetAnalyzed: 'File not yet analyzed',
      conversionReady: 'File converted to PDF',
      loadingDiagramAnalysis: 'Loading diagram ...',
      diagramAnalysisLoaded: 'Diagram loaded',
      diagramRestrictedOneTimePurchaseEnabled: (upgradeButton) => <span>You can analyze only several first diagrams in this eBook. {upgradeButton} your account or click <b>Get Full Access To This Book</b> to get unlimited access</span>,
      diagramRestrictedOneTimePurchaseDisabled: (upgradeButton) => <span>You can analyze only several first diagrams in this eBook. {upgradeButton} your account to get unlimited access</span>,
      noDiagramFound: 'No diagram found',
      error: 'Error occurred',
      uploadingFile: 'Uploading file...',
      uploadingFilePaused: 'Uploading file paused',
    },
    checkout: {
      purchase: {
        successful: {
          title: "Purchase Successful",
          text: <span>Thank you!<br/> Now you can use the <b>full functionality</b> of the reader with <b>your eBook</b>.<br/>Enjoy!</span>,
        },
        canceled: {
          title: "Purchase Canceled",
          text: "Your purchase was canceled.",
        },
      },
      subscription: {
        successful: {
          title: "Subscription Successful",
          text: <span>Thank you!<br/> Now you are a <b>Member</b> and you can use the <b>full functionality</b> of the Reader with <b>all your eBooks</b>.<br/>Enjoy!</span>,
        },
        canceled: {
          title: "Purchase Canceled",
          text: "Your subscription was not purchases.",
        },
      },
    },
    requestAnalyzeDialog: {
      title: 'Analyze this file?',
      text: 'It looks like this file has not yet been analyzed. Each file needs to be analyzed only once. Do you want to analyze it now?',
      analyzeButton: 'Analyze',
    },
    fileSizeExceededDialog: {
      title: 'File is too large',
      text: <>
        File you selected exceeds the maximum file size of 50MB.<br />
        Please upload a smaller file or split your file into smaller parts,
        e.g. two halves, using this recomended
        tool: <a target="_blank" rel="noopener noreferrer" href="https://www.ilovepdf.com/split_pdf#split,range">Split PDF Online</a>
      </>,
    },
    conversionDialog: {
      title: 'Convert and Analyze this file?',
      convertButton: 'Convert and Analyze',
    },
    unsupportedFileFormatDialog: {
      title: 'Unsupported file format',
    },
    setupDialog: {
      title: 'What can you do with it?',
      analysisBoardTitle: 'What you can do on the Analysis Board?',
    }
  },

};

export default settings;
