
import React, { Component } from 'react';

import gray from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';

import { eventTypes, emitEvent } from '../events';

const styles = (theme) => ({
  buttonDisabled: {
    backgroundColor: `${gray[300]} !important`,
  },
  progress: {
    marginRight: theme.spacing(1),
  }
});

class CheckoutButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectingToCheckout: false,
    };
    this.beginCheckout = this.beginCheckout.bind(this);
  }

  beginCheckout() {
    const { user, stripe, url, payload } = this.props;

    emitEvent(eventTypes.checkoutBegin);

    this.setState({
      redirectingToCheckout: true,
    });

    return user.getIdToken().then(authToken => {
      return fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
         'Authorization': authToken,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
      })
      .then(response => response.json())
      .then(session => {
        stripe.redirectToCheckout({
          sessionId: session.id,
        })
      })
      .catch(error => {
        this.setState({
          redirectingToCheckout: false,
        });
      });
    });
  }

  render() {
    // Styling
    const { classes } = this.props;

    const { disabled, text, color, fullWidth } = this.props;
    const { redirectingToCheckout } = this.state;
    return (
      <Elements>
        <Button
          color={color || "primary"}
          variant="contained"
          onClick={this.beginCheckout}
          disabled={redirectingToCheckout || disabled}
          classes={{ root: classes.button, disabled: classes.buttonDisabled }}
          fullWidth={fullWidth || false}
        >
          {redirectingToCheckout &&
            <CircularProgress size={20} className={classes.progress} />
          }
          {text}
        </Button>
      </Elements>
    )
  }
}

CheckoutButton.propTypes = {
  user: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const stripeWrapper = (Component) => ((props) => (
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
    <Elements>
      <Component {...props} />
    </Elements>
  </StripeProvider>
));


export default stripeWrapper(injectStripe(withStyles(styles)(CheckoutButton)));
