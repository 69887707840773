import React from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import grey from '@material-ui/core/colors/grey';

import { withStyles } from '@material-ui/core/styles';

import Filters from './Filters';
import Results from './Results';

import { encodeQueryStr } from './utils';

import { emitEvent, eventTypes } from '../../events';

const styles = (theme) => ({
  container: {
    maxWidth: '1024px',
    margin: '0 auto',
  },
  welcomeContainer: {
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem',
  },
  welcomeTitle: {
    fontSize: '3rem',
    color: grey[900],
    fontWeight: 800,
    letterSpacing: '-0.025em',
  },
  welcomeSubtitle: {
    fontSize: '1.25rem',
    maxWidth: '48rem',
    color: grey[500],
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1.25rem',
  },
  loading: {
    display: 'block',
    margin: '8rem auto',
  },
  resultsContainer: {
    marginTop: '1rem',
    backgroundColor: '#f9f9f9',
    padding: '0rem 1rem',
  },
  moreResultsContainer: {
    paddingBottom: '1rem',
  },
  resultsRestrictedContainer: {
    paddingBottom: '1rem',
  },
});


class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingMore: false,

      docs: null,
      docIds: null,

      fen: null,
      doc: null,

      opening: null,
      variation: null,
      phase: null,
      // phase: { label: "Endgame", value: "endgame" },
      openness: null,
      endgameType: null,
      pawnStructure: null,
      themes: null,
      results: null,
      resultsId: null,
    };
  }

  componentDidMount() {
    this.fetchDocuments();
  }

  fetchDocuments() {
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/analysis/`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
         })
       })
       .then(response => response.json())
       .then(data => {
         const docs = data
         .filter(e => e.finished_at !== null)
         .map(({ id, title, checksum }) => ({ id, title, checksum }));
         docs.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase()) ? -1 : 1);
         const docIds = Object.fromEntries(docs.map(({ id, ...rest }) => [id, rest]));
         this.setState({
           docs,
           docIds,
         });
       });
     });
  }

  handleFilterChange = (key, obj) => {
    const updates = { [key]: obj };
    if (key === 'opening') {
      updates.variation = null;
    }
    if (key === 'phase') {
      updates.endgameType = null;
    }
    this.setState(updates, () => {
      this.handleSearch();
    });
  }


  getSearchParams = () => {
    const {
      doc, fen, opening, variation, phase, openness, endgameType, pawnStructure, themes,
    } = this.state;
    const params = {};
    if (doc !== null) {
      params.analysis_id = doc.value;
    }
    if (fen !== null) {
      params.position = fen;
    }
    if (opening !== null) {
      params.opening = opening.value;
    }
    if (variation !== null) {
      params.variation = variation.value;
    }

    if (phase !== null) {
      params.phase = phase.value;
    }
    if (endgameType !== null) {
      params.endgame_type = endgameType.value;
    }

    if (openness !== null) {
      params.openness = openness.value;
    }

    if (pawnStructure !== null) {
      params.pawn_structure = pawnStructure.value;
    }

    if (themes !== null) {
      params.themes = themes.map(theme => theme.value);
    }
    return params;
  }

  searchParamsToQueryString = (params) => {
    const { analysis_id, position, themes, ...rest } = params;

    let tags = Object.entries(rest).map(([k, v]) => `${k}:${v}`);

    if (themes !== undefined) {
      for (const theme of themes) {
        tags.push(`theme:${theme}`);
      }
    }
    tags.sort();

    let query = [];

    if (analysis_id !== undefined) {
      query.push(`analysis_id=${analysis_id}`);
    }
    if (position !== undefined) {
      query.push(`position=${position}`);
    }
    if (tags.length > 0) {
      query.push(`tags=${tags.join(",")}`)
    }

    if (query.length === 0) {
      return null;
    }
    const queryStr = query.join("&");
    return queryStr;
  };


  handleSearch = (queryMore = false) => {
    const searchParams = this.getSearchParams();
    const queryStr = this.searchParamsToQueryString(searchParams)
    if (queryStr === null) {
      return;
    }
    const { user } = this.props;

    return user.getIdToken().then(authToken => {
      const { resultsId } = this.state;
      this.setState({
        loading: !queryMore,
        loadingMore: queryMore,
        resultsId: queryMore ? resultsId : null,
      }, () => {
      let url = `${process.env.REACT_APP_API_URL}/diagrams/search?${queryStr}`;
      if (queryMore) {
        url += `&results_id=${resultsId}`;
      }
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
         })
       })
       .then((response) => response.json())
       .then((data) => {
          emitEvent(eventTypes.searchPerform, searchParams);
          const {
            query_string: responseQueryString, results, results_id,
            results_restricted: resultsRestricted,
          } = data;
          const { docIds } = this.state;
          const queryStringNow = encodeQueryStr(queryStr);
          // check if the response corresponds to the current filters
          // we normalize both strings with replacing "'" with "%27" because it seems there
          // are differences between productions and development apis there
          if (responseQueryString.replace(/'/g, '%27') === queryStringNow.replace(/'/g, '%27')) {
            const newResults = results
            .filter(({ analysis_id }) => docIds[analysis_id] !== undefined)
            .map(({analysis_id, ...rest}) => ({
              analysis_id,
              analysis_title: docIds[analysis_id].title,
              analysis_checksum: docIds[analysis_id].checksum,
              ...rest,
            }));
            this.setState({
              results: queryMore ? [...this.state.results, ...newResults] : newResults,
              loading: false,
              loadingMore: false,
              resultsId: results_id,
              resultsRestricted,
            });
          }
        });
      })
    });
  }

  render() {
    const { classes } = this.props;

    const { onUpgradeClick } = this.props;

    const { docs } = this.state;
    const { fen, doc } = this.state;
    const { opening, variation, phase, openness, endgameType, pawnStructure, themes } = this.state;
    const { results, resultsId, resultsRestricted, loading, loadingMore } = this.state;

    const atLeastOneDefined = fen || doc || opening || phase || openness || pawnStructure || (themes && themes.length > 0);
    return (
      <div className={classes.container}>
        <div className={classes.filtersWrapper}>
          <Filters
            docs={docs}
            fen={fen}
            document={doc}
            opening={opening}
            variation={variation}
            phase={phase}
            openness={openness}
            endgameType={endgameType}
            pawnStructure={pawnStructure}
            themes={themes}
            onChange={this.handleFilterChange}
          />
        </div>
        { loading && atLeastOneDefined && (
          <CircularProgress className={classes.loading} />
        )}
        { !loading && (results ===  null || !atLeastOneDefined) && (
          <div className={classes.welcomeContainer}>
            <Typography variant="h1" align="center" className={classes.welcomeTitle}>
              Search Diagrams in your books
            </Typography>
            <Typography variant="body2" align="center" className={classes.welcomeSubtitle}>
              Define one or more filters and themes. <br />
              The results point to the diagrams from your books that match all the defined filters.
            </Typography>
          </div>
        )}
        { !loading && (results !== null && atLeastOneDefined) && (
          <div className={classes.resultsContainer}>
            <div className={classes.sortersWrapper}>
            </div>
            <Results results={results} />
            { resultsId && loadingMore && (
              <div className={classes.moreResultsContainer}>
                <CircularProgress className={classes.loading} />
              </div>
            )}
            { results.length > 0 && resultsId && !loadingMore && (
              <div className={classes.moreResultsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { this.handleSearch(true)}}>More Results</Button>
              </div>
            )}
            { results.length > 0 && resultsRestricted && !loadingMore && (
              <div className={classes.resultsRestrictedContainer}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    emitEvent(eventTypes.subscriptionPlansDialogOpenDiagramSearch);
                    onUpgradeClick();
                  }}
                >
                 Upgrade to see more results
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Search);
