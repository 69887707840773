import React, { Component } from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import DialogContentText from '@material-ui/core/DialogContentText';

import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import EmailIcon from '@material-ui/icons/Email';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import Profile from '../../../layout/Profile/Profile';

import ConfirmationDialog from '../../../dialogs/ConfirmationDialog/ConfirmationDialog';
import { CustomerPortalButton } from '../../../buttons/';

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(0),
  },

  dialogContentTextUserComplete: {
    marginTop: theme.spacing(1)
  },

  listItemContainer: {
    display: 'flex',
  },

  listItem: {
    paddingRight: '1rem',
  },

  listItemSecondaryAction: {
    transform: 'none',
    float: 'none',
    marginTop: '1.5rem',
    position: 'static',
    minWidth: '14rem',
  },

  actionButtons: {
  },
  actionButtonWrapper: {
    marginBottom: '0.5rem',
  },
});

const AccountType = ({ subscription, subscriptionCurrentPeriodActive }) => {
  if (subscription === undefined || subscriptionCurrentPeriodActive === undefined) {
    return '';
  }
  const isWebSubscriptionActive = subscription !== null && subscription.ended_at === null;
  const isCurrentPeriodActive = !!subscriptionCurrentPeriodActive;

  if (isWebSubscriptionActive) {
    return (
      <React.Fragment>
        <span>Member, unlimited scans</span>
        { subscription.cancel_at_period_end && (
          <span>
            <br />
            Cancels at {moment.unix(subscription.current_period_end).format('LLLL')}
          </span>
        )}
      </React.Fragment>
    );
  } else if (isCurrentPeriodActive) {
    return (
      <React.Fragment>
        <span>Member, unlimited scans</span>
      </React.Fragment>
    );
  }
  // default return for non-active subscription
  return (
    <span>Free, up to first few diagrams analyzed per document</span>
  );
}

class AccountTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountDeletionInProgress: undefined,
      deleteStep: null,
      deleteConfirmText: '',
      isCreatingAccountDeletionRequest: false,

      cancelSubscriptionDialog: {
        open: false
      },
      isFetchingSubscription: false,
      subscription: undefined,
      isFetchingSubscriptionCurrentPeriodEnd: false,
      subscriptionCurrentPeriodActive: undefined,

      affiliateId: undefined,
    };
    this.handleCancelSubscription = this.handleCancelSubscription.bind(this);
  }

  componentDidMount() {
    this.fetchSubscription();
    this.fetchSubscriptionCurrentPeriodEnd();
    this.fetchAccountDeletionInfo();
    this.fetchAffiliateId();
  }

  fetchAccountDeletionInfo() {
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/account_deletion_request/`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
        }),
      })
      .then(response => {
        if (response.ok) {
          this.setState({
            accountDeletionInProgress: true,
          });
        } else if (response.status === 404) {
          this.setState({
            accountDeletionInProgress: false,
          });
        } else {
          console.error('Unknown error occurred while checking account deletion request status');
        }
      });
    });
  }

  advanceDeleteDialog = () => {
    const { deleteStep } = this.state;
    this.setState({
      deleteStep: deleteStep === null ? 1 : deleteStep + 1,
    });
  }

  createAccountDeletionRequest() {
    const { user } = this.props;
    this.setState({
      isCreatingAccountDeletionRequest: true,
    }, () => {
      return user.getIdToken().then(authToken => {
        const url = `${process.env.REACT_APP_API_URL}/account_deletion_requests/`;
        return fetch(url, {
          method: 'POST',
          headers: new Headers({
            'Authorization': authToken,
          }),
        })
        .then(response => {
          if (response.ok) {
            this.setState({
              isCreatingAccountDeletionRequest: false,
              accountDeletionInProgress: true,
              deleteStep: 3,
            });
          } else {
            console.error('Unknown error occurred while creating account deletion request');
          }
        });
      });
    });
  }

  dismissDeleteDialog = () => {
    this.setState({
      deleteStep: null,
      deleteConfirmText: '',
    });
  }

  fetchAffiliateId() {
    const { user } = this.props;

    user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/affiliate_ids/`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
        }),
      })
      .then(response => {
        if (response.ok) {
          return response.json()
          .then(data => {
            this.setState({
              affiliateId: data,
            });
          })
        }
      })
    });
  }

  fetchSubscription() {
    const { getSubscription} = this.props;

    this.setState({
      isFetchingSubscription: true,
    }, () => {
      getSubscription(
        (subscription) => {
          this.setState({
            isFetchingSubscription: false,
            subscription,
          });
        },
        () => {
          this.setState({
            isFetchingSubscription: false,
            subscription: null,
          });
        }
      )
    });
  }

  fetchSubscriptionCurrentPeriodEnd() {
    const { getSubscriptionCurrentPeriodEnd } = this.props;

    this.setState({
      isFetchingSubscriptionCurrentPeriodEnd: true,
    }, () => {
      getSubscriptionCurrentPeriodEnd(
        (currentPeriodEnd) => {
          const now = Date.now() / 1000;
          const isActive = currentPeriodEnd && currentPeriodEnd > now;
          this.setState({
            isFetchingSubscriptionCurrentPeriodEnd: false,
            subscriptionCurrentPeriodActive: isActive,
          });
        },
        () => {
          this.setState({
            isFetchingSubscriptionCurrentPeriodEnd: false,
            subscriptionCurrentPeriodActive: false,
          });
        }
      )
    });
  }

  handleCancelSubscription() {
    const { onCancelSubscriptionClick } = this.props;

    this.setState({
      isFetchingSubscription: true,
    }, () => {
      onCancelSubscriptionClick()
      .then(subscription => {
        this.setState({
          isFetchingSubscription: false,
          subscription,
        });
        this.closeCancelSubscriptionDialog();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isFetchingSubscription: false,
        });
        this.closeCancelSubscriptionDialog();
      });
    });
  }

  openCancelSubscriptionDialog = () => {
    this.setState({
      cancelSubscriptionDialog: {
        open: true
      }
    });
  };

  closeCancelSubscriptionDialog = (callback) => {
    this.setState({
      cancelSubscriptionDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const {
      user, isPerformingAuthAction, onUpgradeClick,
    } = this.props;

    const {
      cancelSubscriptionDialog, 
      isFetchingSubscription, subscription,
      isFetchingSubscriptionCurrentPeriodEnd, subscriptionCurrentPeriodActive,
      affiliateId,
    } = this.state;

    const isUserComplete = (user.photoURL && user.displayName && user.email);

    const { accountDeletionInProgress } = this.state;
    const { deleteStep, deleteConfirmText } = this.state;
    const { isCreatingAccountDeletionRequest } = this.state;

    return (
      <React.Fragment>
        <Profile user={user} extraTopMargin />

        <DialogContentText classes={{ root: classes.root }} className={isUserComplete && classes.dialogContentTextUserComplete}>
          Here's some info about your account.
        </DialogContentText>

        <List>
          {user.email &&
            <ListItem>
              <ListItemIcon>
                <Tooltip title="E-mail address">
                  <EmailIcon />
                </Tooltip>
              </ListItemIcon>

              <ListItemText primary={user.email} />
            </ListItem>
          }
          {affiliateId &&
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Affliate code">
                  <GroupWorkIcon />
                </Tooltip>
              </ListItemIcon>

              <ListItemText primary={`Affiliate code: ?ref_id=${affiliateId}`} secondary={`Append this to any ebook.chessvision.ai links (for example www.ebook.chessvision.ai?ref_id=${affiliateId})`} />
            </ListItem>
          }

          <ListItem classes={{ root: classes.listItem, container: classes.listItemContainer }}>
            <ListItemIcon>
              <Tooltip title="Account Type">
                <AccessTimeIcon />
              </Tooltip>
            </ListItemIcon>

            <ListItemText
              primary="Account Type"
              secondary={<AccountType subscription={subscription} subscriptionCurrentPeriodActive={subscriptionCurrentPeriodActive} />}
            />

            <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
              { isFetchingSubscription &&
                <CircularProgress />
              }
              { !isFetchingSubscription && !isFetchingSubscriptionCurrentPeriodEnd && subscription !== undefined && subscriptionCurrentPeriodActive !== undefined
                && (subscription === null || subscription.ended_at) && !subscriptionCurrentPeriodActive &&
                <Button color="secondary" user={user} disabled={isPerformingAuthAction} variant="contained" onClick={onUpgradeClick}>Upgrade</Button>
              }
              {
                /*
                !isFetchingSubscription && subscription !== undefined && subscription !== null && subscription.ended_at === null && subscription.cancel_at_period_end &&
                <Button color="secondary" disabled={isPerformingAuthAction} variant="contained" onClick={onExtendClick}>Extend</Button>
                */
              }
              {/* For Web Subscriptions */}
              { !isFetchingSubscription && subscription !== undefined && subscription !== null && subscription.ended_at === null && !subscription.cancel_at_period_end &&
                <div className={classes.actionButtons}>
                  <div className={classes.actionButtonWrapper}>
                    <CustomerPortalButton user={user} size="small" text="Update Billing Info" />
                  </div>
                  <div className={classes.actionButtonWrapper}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={this.openCancelSubscriptionDialog}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              }
              {/* For Mobile Subscriptions */}
              { (!isFetchingSubscription && subscription !== undefined && (subscription === null || subscription.ended_at !== null)) &&
                !isFetchingSubscriptionCurrentPeriodEnd && subscriptionCurrentPeriodActive !== undefined && subscriptionCurrentPeriodActive &&
                <ListItemText 
                  secondary="If you purchased the subscription on your mobile device, you can manage it from there, e.g. App Store or Google Play Store"
                />
              }
            </ListItemSecondaryAction>
          </ListItem>

          {
          /*
          {user.metadata.lastSignInTime &&
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Last sign-in">
                  <AccessTimeIcon />
                </Tooltip>
              </ListItemIcon>

              <ListItemText primary="Last sign-in" secondary={moment(user.metadata.lastSignInTime).format('LLLL')} />
            </ListItem>
          }

          {user.metadata.creationTime &&
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Signed up">
                  <AccessTimeIcon />
                </Tooltip>
              </ListItemIcon>

              <ListItemText primary="Signed up" secondary={moment(user.metadata.creationTime).format('LLLL')} />
            </ListItem>
          }
          */
          }
          <Divider style={{ marginTop: 64 }} />
          <ListItem>
          { accountDeletionInProgress && (
            <span>
              Account deletion is in progress. 
              It will take at most 7 business days to delete the account, but usually, it will happen sooner.
              We will send a confirmation email to {user.email} when the deletion is completed.
            </span>
          )}
          { accountDeletionInProgress === false && (
            <Button
              onClick={this.advanceDeleteDialog}
              disabled={deleteStep !== null}
            >Delete Account</Button>
          )}
          </ListItem>
        </List>

        <ConfirmationDialog
          open={cancelSubscriptionDialog.open}

          title="Cancel subscription?"
          content={`If you cancel the subscription you'll be able to fully use the Reader${subscription !== undefined && subscription !== null ? ' until ' + moment.unix(subscription.current_period_end).format('LLLL') : ''}. After this date, you'll be not able to use all funtionality of the Reader. Do you still want to cancel the subscription?`}
          okText="Do Not Cancel"
          cancelText="Cancel Subscription"
          disableCancelButton={isFetchingSubscription}
          highlightOkButton

          onClose={this.closeCancelSubscriptionDialog}
          onCancelClick={this.handleCancelSubscription}
          onOkClick={this.closeCancelSubscriptionDialog}
        />

        { /* Delete account step dialogs */ }
        <ConfirmationDialog
          open={deleteStep === 1}
          title="Do you want to start the account deletion process?"
          content={`If you have any active auto-renewable subscriptions, cancel them before continuing to ensure that your billinng will not continue.`}
          okText="Cancel"
          cancelText="Delete account"
          highlightOkButton

          onClose={this.dismissDeleteDialog}
          onCancelClick={this.advanceDeleteDialog}
          onOkClick={this.dismissDeleteDialog}
        />
        <ConfirmationDialog
          open={deleteStep === 2}
          title="Delete account"
          content={`If you want to delete the account for email ${user.email} with all associated data, write DELETE in the below input and then click the Delete Account button. This process is irreversible.`}
          okText="Cancel"
          cancelText="Delete account"
          highlightOkButton
          disableCancelButton={deleteConfirmText !== 'DELETE' || isCreatingAccountDeletionRequest}

          onClose={this.dismissDeleteDialog}
          onCancelClick={() => { this.createAccountDeletionRequest() }}
          onOkClick={this.dismissDeleteDialog}
        >
          <TextField
            size="small"
            margin="none"
            onChange={e => { this.setState({ deleteConfirmText: e.target.value.toUpperCase() }) }}
            variant="outlined"
            helperText="type DELETE above"
            value={deleteConfirmText}
          />
        </ConfirmationDialog>
        <ConfirmationDialog
          open={deleteStep === 3}
          title="Account deletion process started"
          content={`We received your request to start the account deletion process. It will take at most 7 business days to delete the account, but usually, it will happen sooner. We will send a confirmation email to ${user.email} when the deletion is completed.`}
          okText="Ok"
          highlightOkButton

          onClose={this.dismissDeleteDialog}
          onOkClick={this.dismissDeleteDialog}
        />

      </React.Fragment>
    );
  }
}

AccountTab.propTypes = {
  classes: PropTypes.object.isRequired,

  user: PropTypes.object.isRequired,
  isPerformingAuthAction: PropTypes.bool.isRequired,

  onCancelSubscriptionClick: PropTypes.func.isRequired,
  onUpgradeClick: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
  getSubscriptionCurrentPeriodEnd: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountTab);
