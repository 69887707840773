import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import Divider from '@material-ui/core/Divider';

import CTA from './CTA';
import { AndroidButton, AppleButton } from './MobileButton';
import Stepper from './Stepper';
import Stats from './Stats';
import Award from './Award';
import Reviews from './Reviews';
import Pricing from './Pricing';
import Personal from './Personal';
import Footer from '../../Site/Footer';

const styles = (theme) => ({
  root: {
    justifyContent: 'center',
    textAlign: 'center',
    color: '#6b7280',
  },
  header: {
    paddingTop: theme.spacing(28),
    paddingBottom: theme.spacing(40),
    backgroundColor: '#f9fafb',
    '@media (max-width:768px)': {
      paddingTop: theme.spacing(16),
    },
  },
  title: {
    fontSize: '3rem',
    color: '#161e2e',
    fontWeight: 600,
    letterSpacing: '-0.025em',
  },
  subtitle: {
    textAlign: 'center',
  },
  ctaContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  ctaMobileContainer: {
    display: 'flex',
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    margin: '0 auto',
    marginTop: '-16rem',
    '& object': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& embed': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  description: {
    backgroundColor: 'white',
    paddingTop: theme.spacing(10),
  },
  stepper: {
    marginTop: theme.spacing(15),
    textAlign: 'center',
  },
  testimonials: {
    backgroundColor: grey[50],
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  pricing: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(8),
    color: 'white',
  },
  pricingTopHeader: {
    color: grey[300],
    marginBottom: '0.5rem',
  },
  pricingHeader: {
    color: 'white',
  },
  pricingSubtitle: {
    marginTop: '0.5rem',
    color: grey[300],
  },
  pricingWrapper: {
    marginTop: '2rem',
    paddingBottom: '2rem',
  },
  personal: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
  },
  bottomIncentive: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  bottomIncentiveWrapper: {
    display: 'block',
  },
});

class LandingContent extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { onSignUpClick } = this.props;

    const youtubeUrl = 'https://www.youtube.com/embed/5wIv3OP7VWM?rel=0';
    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.header}>
            <Container maxWidth="md">
              <Typography variant="h1">Smart Reader unlocking</Typography>
              <Typography color="secondary" variant="h1">full power of chess eBooks</Typography>
              <Typography color="inherit" variant="body2">
                Use Artificial Intelligence to make your chess eBooks interactive and take your study to the next level. Supports <b>PDF</b>, <b>ePub</b>, and <b>DjVu</b> formats.
              </Typography>
              <div className={classes.ctaContainer}>
                <CTA onClick={onSignUpClick} />
                <div className={classes.ctaMobileContainer}>
                  <AndroidButton />
                  <AppleButton />
                </div>
              </div>
            </Container>
          </div>
          <div className={classes.videoContainer}>
            <Container maxWidth="md">
              <div className={classes.videoWrapper}>
                <iframe
                  title="Showcase Video"
                  src={youtubeUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  className={classes.video}
                  allowFullScreen>
                </iframe>
              </div>
            </Container>
          </div>
          <div className={classes.description} id="description">
            <Container maxWidth="md">
              <Typography variant="h1">
                So simple yet powerful
              </Typography>
              <Typography color="inherit" variant="body2">
                Open any <b>PDF</b>, <b>ePub</b>, or <b>DjVu</b> chess book you have and let the Reader process it. Once it finishes, double-click on any chess diagram to open it for analysis.
              </Typography>
              <Stepper />
              <Typography color="inherit" variant="body1">
                Any eBook is processed only once. Next time you open it, it is ready to use. Unlike others, we do not manually enhance chess ebooks and sell them for premium prices.
                Instead, we use Computer Vision and Artificial Intelligence to make any book you already have interactive.
              </Typography>
              <div className={classes.ctaContainer}>
                <CTA onClick={onSignUpClick} />
                <div className={classes.ctaMobileContainer}>
                  <AndroidButton />
                  <AppleButton />
                </div>
              </div>
            </Container>
          </div>
          <div className={classes.testimonials} id="proof">
            <Container maxWidth="md">
              <Typography variant="h1">Chess players love it</Typography>
              <Stats />
            </Container>
            <Reviews />
            <Divider style={{ marginTop: '2rem' }}/>
            <Container maxWidth="md">
              <Award />
            </Container>
          </div>
          <div className={classes.pricing} id="pricing">
            <Container maxWidth="md">
              <Typography variant="h4" className={classes.pricingTopHeader}>Pricing</Typography>
              <Typography variant="h2" className={classes.pricingHeader}>Simple plans for different needs</Typography>
              <Typography variant="body1" className={classes.pricingSubtitle}>
                Try for free with any eBook you want. No credit card required.
              </Typography>
            </Container>
            <div className={classes.pricingWrapper}>
              <Pricing />
            </div>
            <Container maxWidth="md">
              <div className={classes.ctaContainer}>
                <CTA onClick={onSignUpClick} />
                <div className={classes.ctaMobileContainer}>
                  <AndroidButton />
                  <AppleButton />
                </div>
              </div>
            </Container>
          </div>
          <div className={classes.personal}>
            <Container maxWidth="md">
              <Typography variant="h2">Made by real people for real people</Typography>
              <Personal />
            </Container>
          </div>
          <div className={classes.bottomIncentive}>
            <Container maxWidth="md">
              <div className={classes.bottomIncentiveWrapper}>
                <Typography variant="h2">Ready to dive in?</Typography>
                <Typography color="secondary" variant="h2">Unlock full power of chess eBooks today.</Typography>
                <div className={classes.ctaContainer}>
                  <CTA onClick={onSignUpClick} />
                  <div className={classes.ctaMobileContainer}>
                    <AndroidButton />
                    <AppleButton />
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

LandingContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingContent);
