import React, { Component } from 'react';

import './Reader.css';

// Firebase
import firebase from 'firebase/app';

import TopBar from './TopBar';
import BoardAnalysis from './BoardAnalysis';
import EditMode from './EditMode';
import Tiles from './Tiles';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";

import PDFViewer from './PDFViewer/PDFViewer';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import ConfirmationDialog from '../../dialogs/ConfirmationDialog/ConfirmationDialog';
import { PurchaseButton } from '../../buttons';

import { fileChecksum } from '../../utils';
import { getFenWithPlayer, startingFen, reverseFenOrientation } from './utils';
import { getFileType } from './utils';
import { getUnsupportedContent, getConversionContent } from './utils';

import settings from '../../settings';
import { eventTypes, emitEvent } from '../../events';

import ClipboardIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/CloseOutlined';


import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import Videos from './Videos';
import StudyEdit from './StudyEdit';

import { fensToPdfUrl, fensToLaTeX } from './pdfoutput';

const styles = theme => ({
  root: {
    position: 'fixed',
    paddingTop: '3.5rem',
    height: '100%',
    background: 'gray',
    minHeight: '100vh',
  },
  rootFullscreen: {
    paddingTop: 0,
  },
  controlsWrapper: {
    background: 'white',
    width: '100vw',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: theme.layoutDirection === 'ltr' ? 'row' : 'row-reverse',
  },
  viewerWrapper: {
    flex: 1,
    position: 'relative',
  },
  tilesWrapper: {
    overflowY: 'auto',
    position: 'absolute',
    zIndex: 999,
    top: 40,
    width: '100%',
  },
  tilesInner: {
    maxWidth: 1200,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dragWrapper: {
    flexDirection: theme.layoutDirection === 'ltr' ? 'row' : 'row-reverse',
  },
  analysisWrapper: {
    height: "100vh",
    width: '400px',
  },
  studyDialogTextarea: {
    marginTop: '1rem',
  },
  studyDialogTab: {
    textTransform: 'none', 
    minWidth: '120px',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2.75),
    right: theme.spacing(12),
  },
  dragBar: {
    marginLeft: theme.layoutDirection === 'ltr' ? '3px' : 0,
    marginRight: theme.layoutDirection === 'ltr' ? 0 : '3px',
    height: '100vh',
    width: '4px',
    background: 'white',
    cursor: 'col-resize',
    '&:hover': {
      opacity: 1,
    },
  },
  dragGhost: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 99999999,
    cursor: 'col-resize',
  },
  videosWrapper: {
    backgroundColor: 'white',
  },
  hidden: {
    visibility: 'hidden',
    width: 0,
  },
  visible: {
    visibility: 'visible',
    [`@media (max-width: ${settings.values.maxMobileWidth}px)`]: {
      width: '100%',
    },
  },
  snackbarContentAction: {
    paddingLeft: 8,
  },
});

const studyDiagramToPGN = (diagram, attachMetadata) => {
  const { fen, comment } = diagram;

  let out = '[Result "*"]\n';
  out += `[FEN "${fen.replaceAll('_', ' ')}"]`;
  if (comment !== "") {
    out += `\n{ ${comment} }`;
  }
  out += '\n\n*\n';
  return out;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Reader extends Component {

  fetchTimeout = null;
  lastTouchX = 0;
  commentSaveTimer = null;

  constructor(props) {
    super(props);
    const { isMobile } = this.props;

    let initStudy = { diagrams: [] };
    const savedStudy = window.localStorage.getItem(settings.values.studyCreatorKey);
    if (savedStudy !== null) {
      initStudy = JSON.parse(savedStudy);
    }

    this.state = {
      fullscreen: false,
      file: null,
      fileType: null,
      remoteFile: null,
      checksum: null,
      status: {
        running: false,
        progress: null,
        type: 'info',
        text: settings.messages.reader.noFileOpened,
      },
      videoTutorialsDialog: {
        open: false,
      },
      processDialog: {
        open: false
      },
      fileSizeExceededDialog: {
        open: false,
      },
      unsupportedFileFormatDialog: {
        open: false,
        content: null,
      },
      conversionDialog: {
        open: false,
        content: null,
      },
      analysis: {
        id: null,
      },
      analysisWindow: {
        open: isMobile ? false : true,
      },
      fen: startingFen,
      pov: null,
      comment: "",
      lastDiagram: {
        pageNum: null,
        x: null,
        y: null,
      },

      studyExportDialog: {
        open: false,
        activeTab: 1,
        pdf: {
          leftHeader: '',
          rightHeader: '',
          leftFooter: '',
          enumerateFrom: 1,
        },
      },

      editMode: {
        open: false,
      },

      videosMode: {
        open: false,
        data: [],
      },

      freeBook: {
        loading: false,
        open: false,
      },

      study: initStudy,
      studyCopiedToClipboard: false,

      isDragging: false,
      analysisBoardWidth: 400,
    };

    const { location } = this.props;
    if (location.state !== undefined && location.state.hasOwnProperty('remoteFile')) {
      const { remoteFile } = location.state;
      this.openRemoteFile(remoteFile);
    } else {
      const key = settings.values.newTabRemoteFileKey;
      const obj = window.localStorage.getItem(key);
      if (obj !== null) {
        const remoteFile = JSON.parse(obj);
        window.localStorage.removeItem(key);
        this.openRemoteFile(remoteFile);
      } else if (location.search !== undefined) {
        const remoteFileChecksum = new URLSearchParams(location.search).get("remoteFileChecksum")
        if (remoteFileChecksum !== null) {
          const remoteFile = {
            id: 999999999,  // some temp id for now
            checksum: remoteFileChecksum,
            title: "Mat-2022-1-150.pdf",
          };
          this.openRemoteFile(remoteFile);
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.fetchTimeout !== null) {
      clearTimeout(this.fetchTimeout);
    }
  }

  openRemoteFile = ({ id, checksum, title, url, page }) => {
    const { getFileUrl } = this.props;
    getFileUrl(checksum).then(url => {
      this.setState({
        checksum,
      }, () => {
        this.setState({
          remoteFile: {
            id, checksum, title, url, page
          }
        }, () => {
          emitEvent(eventTypes.documentOpenCloud);
          this.fetchAnalysis();
        });
      });
    });
  }

  loadFreeBook = (checksum) => {
    const { user } = this.props;

    return user.getIdToken().then(authToken => {
      this.setState({
        freeBook: { loading: true, open: false },
      });
      const url = `${process.env.REACT_APP_API_URL}/free-books/${checksum}`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
         'pragma': 'no-cache',
         'cache-control': 'no-cache',
       })
      });
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
        .then(data => {
          const { id, checksum, title, url, page } = data;
          this.setState({
            freeBook: { loading: false, open: false },
          }, () => {
            this.openRemoteFile({ id, checksum, title });
            setTimeout(() => { this.setState({ freeBook: { loding: false, open: true }})}, 10000)
          });
        });
      } else {
        return response.json()
        .then((data) => {
          this.setState({
            freeBook: { loading: false, open: false },
            status: {
              running: false,
              text: `Error: ${data.message}`,
              type: 'error',
              progress: null,
            }
          });
        })
      }
    });
  }

  toggleFullscreen = value => {
    const header = document.querySelector('header');
    header.style.visibility = value ? 'collapse' : 'visible';

    const crispIcon = document.querySelector('#crisp-chatbox > div > a');
    if (crispIcon) {
      if (value) {
        crispIcon.style.setProperty('display', 'none', 'important');
      } else {
        crispIcon.style.removeProperty('display');
      }
    }
    this.setState({
      fullscreen: value,
    });
  }

  toggleAnalysis = (open) => {
    const { analysisWindow } = this.state;
    this.setState({
      analysisWindow: {
        ...analysisWindow,
        open,
      }
    });
  }

  openEditMode = () => {
    this.setState({
      editMode: {
        open: true,
      } });
  }

  closeEditMode = () => {
    this.setState({
      editMode: {
        open: false,
      }
    });
  }

  openVideosMode = (data) => {
    emitEvent(eventTypes.relatedVideosOpen);
    this.setState({
      videosMode: {
        open: true,
        data,
      }
    });
  }

  closeVideosMode = () => {
    this.setState({
      videosMode: {
        open: false,
        data: [],
      }
    });
  }

  handleFenChange = (fen) => {
    this.setState({
      fen,
    }, () => this.patchDiagram());
  }


  /*
  // NOTE: for now we are not patching pov, maybe we want to do this in the future; so now handlePovChange is commented out here
  handlePovChange = (pov) => {
    this.setState({
      pov,
    }, () => {
      if (this.state.lastDiagram.pageNum !== null) {
        this.patchDiagram();
      }
    });
  }
  */

  handlePlayerChange = (currentPlayer) => {
    const { fen } = this.state;
    const newFen = getFenWithPlayer(fen, currentPlayer);
    this.handleFenChange(newFen);
  }

  handleOrientationChange = () => {
    const { fen } = this.state;
    const newFen = reverseFenOrientation(fen);
    this.handleFenChange(newFen);
  }

  handleRestorePosition = () => {
    this.setState({ fen: startingFen });
  }

  handleApplyEdit = (fen) => {
    this.closeEditMode();
    this.setState({
      fen,
      lastDiagram: {
        pageNum: null,
        x: null,
        y: null,
      },
    });
  }

  handleSaveEdit = (fen) => {
    this.closeEditMode();
    this.handleFenChange(fen);
  }

  fetchAnalysis = () => {
    const { user } = this.props;
    const { status, file, remoteFile } = this.state;

    if (!file && !remoteFile) {
      return;
    }

    if (!status || !status.running) {
      this.setState({
        status: {
          running: true,
          text: settings.messages.reader.recognizingFile,
          type: 'info',
          progress: null,
        }
      });
    }

    const { checksum } = this.state;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/analysis/${checksum}`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
         'pragma': 'no-cache',
         'cache-control': 'no-cache',
       })
      });
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
        .then(data => {
          if (data.finished_at === null) {
            let text = settings.messages.reader.analyzingFile;
            if (data.status !== null) {
              const { status } = data;
              text = `Analyzing file: step ${status.step}/${status.total_steps}: ${status.operation}`;
              if (status.operation === 'finding diagrams') {
                text += ` on pages ${status.from_page}-${status.to_page}`;
              }
            }
            this.setState({
              status: {
                running: true,
                text,
                type: 'info',
                progress: null,
              }
            }, () => {
              this.fetchTimeout = setTimeout(() => this.fetchAnalysis(),
                settings.values.analysisPoolInterval);
            });
          } else {
            const statusText = data['errors'] ? `Errors: ${data['errors']}` : settings.messages.reader.fileReady;
            const statusType = data['errors'] ? 'error' : 'success';
            this.setState({
              analysis: {
                id: data.id,
                title: data.title,
                full_access: data.full_access,
                price: data.price,
                checksum: data.checksum,
              },
              status: {
                running: false,
                text: statusText,
                type: statusType,
                progress: null,
              }
            });
          }
        })
      } else if (response.status === 404) {
        this.setState({
          status: {
            running: false,
            text: settings.messages.reader.fileNotYetAnalyzed,
            type: 'info',
            progress: null,
          }
        });
        this.openProcessDialog();
      } else {
        return response.json()
        .then((data) => {
          this.setState({
            status: {
              running: false,
              text: `Error: ${data.message}`,
              type: 'error',
              progress: null,
            }
          });
        })
      }
    });
  }

  fetchConversion = () => {
    const { user } = this.props;
    const { status, file } = this.state;

    if (!file) {
      return;
    }

    if (!status || !status.running) {
      this.setState({
        status: {
          running: true,
          text: settings.messages.reader.recognizingFile,
          type: 'info',
          progress: null,
        }
      });
    }

    const { checksum } = this.state;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/conversions/${checksum}`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
         'pragma': 'no-cache',
         'cache-control': 'no-cache',
       })
      });
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
        .then(data => {
          if (data.finished_at === null) {
            const text = data.status === null ? settings.messages.reader.convertingFile : 'Converting file...';
            this.setState({
              status: {
                running: true,
                text,
                type: 'info',
                progress: null,
              }
            }, () => {
              this.fetchTimeout = setTimeout(() => this.fetchConversion(),
                settings.values.conversionPoolInterval);
            });
          } else {
            const statusText = data['errors'] ? `Errors: ${data['errors']}` : settings.messages.reader.conversionReady;
            const statusType = data['errors'] ? 'error' : 'success';
            if (data['errors']) {
              // TODO: think if we should handle errors better?
              // maybe allow to delete the conversion, so it can be performed again if preferred?
              this.setState({
                status: {
                  running: false,
                  text: statusText,
                  type: statusType,
                  progress: null,
                }
              }, () => {
                this.deleteConversion(data['id']);
              });
            } else {
              /* new method */
              const { getFileUrl } = this.props;
              const checksum = data['output_checksum'];
              const title = data['title'];

              getFileUrl(checksum).then(url => {
                this.setState({
                  checksum,
                }, () => {
                  this.setState({
                    file: null, // so that remoteFile can be loaded into the PDF.js reader
                    remoteFile: {
                      id: data['id'], checksum, title, url,
                    }
                  }, () => {
                    this.postAnalysis(checksum, title)
                    .then(response => {
                      if (response.ok) {
                        return response.json()
                        .then(analysis => {
                          this.setState({
                            status: {
                              running: true,
                              text: settings.messages.reader.analyzingFile,
                              type: 'info',
                              progress: null,
                            }
                          });

                          const localBackend = process.env.REACT_APP_API_URL.includes('localhost');
                          // run this only with local backend because analyzing in local backend is a blocking call
                          if (localBackend) {
                            this.fetchTimeout = setTimeout(() => this.fetchAnalysis(), settings.values.analysisPoolInterval);
                          }
                          return this.patchAnalysis(analysis.id)
                          .then(response => response.json())
                          .then(data => {
                            if (!localBackend) {
                              // run this only with non local backends, where analyzing is not a blocking request and returns immediately
                              this.fetchTimeout = setTimeout(() => this.fetchAnalysis(),
                                settings.values.analysisPoolInterval);
                            }
                          });
                        });
                      } else if (response.status === 409) {
                        // conversion already exists
                        this.setState({
                          status: {
                            running: true,
                            text: settings.messages.reader.recognizingFile,
                            type: 'info',
                            progress: null,
                          }
                        }, () => {
                          this.fetchAnalysis();
                        });
                      }
                    })
                  });
                });
              });
            }
          }
        })
      } else if (response.status === 404) {
        this.setState({
          status: {
            running: false,
            text: settings.messages.reader.fileNotYetAnalyzed,
            type: 'info',
            progress: null,
          }
        });
        this.openConversionDialog();
      } else {
        return response.json()
        .then((data) => {
          this.setState({
            status: {
              running: false,
              text: `Error: ${data.message}`,
              type: 'error',
              progress: null,
            }
          });
        })
      }
    });
  }

  postAnalysis = (checksum, filename) => {
    const { user } = this.props;
    const data = {
      checksum,
      title: filename,
    };
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/analysis/`;
      return fetch(url, {
        method: 'POST',
        headers: new Headers({
         'Authorization': authToken,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
        body: JSON.stringify(data)
      })
    });
  }

  patchAnalysis = (analysisId) => {
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/analysis/${analysisId}`;
      return fetch(url, {
        method: 'PATCH',
        headers: new Headers({
         'Authorization': authToken,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
      });
    });
  }

  postConversion = (inputFormat, inputChecksum, filename) => {
    const { user } = this.props;
    const data = {
      input_checksum: inputChecksum,
      input_format: inputFormat,
      title: filename,
    };
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/conversions/`;
      return fetch(url, {
        method: 'POST',
        headers: new Headers({
         'Authorization': authToken,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
        body: JSON.stringify(data)
      })
    });
  }

  patchConversion = (conversionId) => {
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/conversions/${conversionId}`;
      return fetch(url, {
        method: 'PATCH',
        headers: new Headers({
         'Authorization': authToken,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
      });
    });
  }

  deleteConversion = (conversionId) => {
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/conversions/${conversionId}`;
      return fetch(url, {
        method: 'DELETE',
        headers: new Headers({
         'Authorization': authToken,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
      })
    })
  }
  onFileChanged = (file) => {
    // do not replace the current file if the new file is undefined
    // this happens for example while select file window is cancelled
    if (file === undefined || file === null) {
      return;
    }

    const fileType = getFileType(file);

    const unsupportedContent = getUnsupportedContent(fileType);
    if (unsupportedContent !== null) {
      emitEvent(eventTypes.unsupportedFileFormatOpen, { format: fileType });
      this.openUnsupportedFileFormatDialog(unsupportedContent);
      return;
    }

    const conversionContent = getConversionContent(fileType);
    if (conversionContent !== null) {
      // conversion flow
      if (file.size > settings.values.maxFileSize) {
        this.openFileSizeExceededDialog();
        return;
      }
      fileChecksum(file).then(checksum => {
        this.setState({
          file,
          fileType,
          checksum,
          status: null,
          conversionDialog: {
            open: false,
            content: conversionContent,
          },
        }, () => {
          emitEvent(eventTypes.documentOpenDisk, { format: fileType });
          this.fetchConversion();
        });
      });
      return;
    }

    // IMPORTANT: we optimisticly assume that if no file format could be deducted from the file then there is a chance it is
    // a valid PDF file so we try to open it. This is way better that preventing to analyze unknown formats because if
    // they turn out to be PDFs then it can cause huge pain for a user and very negative experience

    // default flow
    if (file.size > settings.values.maxFileSize) {
      this.openFileSizeExceededDialog();
      return;
    }
    fileChecksum(file).then(checksum => {
      this.setState({
        file,
        fileType,
        checksum,
        status: null,
      }, () => {
        emitEvent(eventTypes.documentOpenDisk, { format: fileType });
        this.fetchAnalysis();
      });
    });
  }

  openConversionDialog = () => {
    const { conversionDialog } = this.state;
    this.setState({ conversionDialog: { open: true, content: conversionDialog.content }});
  }

  closeConversionDialog = (callback) => {
    const { open, ...rest } = this.state.conversionDialog;
    this.setState({
      conversionDialog: { open: false, ...rest }
    }, () => {
        if (callback && typeof callback === 'function') {
          callback();
        }
      }
    );
  }

  openUnsupportedFileFormatDialog = (content) => {
    this.setState({ unsupportedFileFormatDialog: { open: true, content }});
  }

  closeUnsupportedFileFormatDialog = () => {
    this.setState({ unsupportedFileFormatDialog: { open: false, content: null }});
  }

  patchDiagram = () => {
    const { analysis, lastDiagram } = this.state;
    if (!analysis.id || lastDiagram.pageNum === null) {
      return;
    }

    const { user } = this.props;
    const { fen, pov, comment } = this.state;
    const { pageNum, x, y } = lastDiagram;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/diagrams/${analysis.id}?page_num=${pageNum-1}&x=${x}&y=${y}`;
      const data = {
        fen,
        // pov,  // NOTE: for now we are not patching pov, maybe we want to do this in the future
        comment,
      };
      return fetch(url, {
        method: 'PATCH',
        headers: new Headers({
         'Authorization': authToken,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
        body: JSON.stringify(data)
      })
      .then(response => {
        if (response.ok) {
          return response.json()
          .then(data => {
            const { fen, pov } = data;
            this.setState({
              fen,
              // pov,
            });
          });
        } else {
          console.error('error while updating diagram');
          console.error(response.status);
        }
      });
    });
  }
  onPageClicked = (pageNum, x, y) => {
    const { user } = this.props;
    const { analysis } = this.state;

    const { isPerformingAuthAction, onUpgradeClick } = this.props;

    if (!analysis.id) {
      return;
    }

    this.setState({
      status: {
        running: true,
        progress: null,
        type: 'info',
        text: settings.messages.reader.loadingDiagramAnalysis,
      },
    });
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/diagrams/${analysis.id}?page_num=${pageNum-1}&x=${x}&y=${y}`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
        }),
      })
      .then(response => {
        if (response.ok) {
          emitEvent(eventTypes.diagramLoadSuccess);
          return response.json()
          .then(data => {
            this.setState({
              fen: data.fen,
              pov: data.pov,
              comment: data.comment || "",
              lastDiagram: {
                pageNum,
                x,
                y,
              },
              status: {
                running: false,
                progress: null,
                type: 'success',
                text: settings.messages.reader.diagramAnalysisLoaded,
              },
            });
            this.toggleAnalysis(true);
            this.closeVideosMode();
          });
        } else if (response.status === 404) {
          emitEvent(eventTypes.diagramLoadNotFound);
          this.setState({
            status: {
              running: false,
              progress: null,
              type: 'info',
              text: settings.messages.reader.noDiagramFound,
            },
          });
        } else if (response.status === 403) {
          emitEvent(eventTypes.diagramLoadForbidden);
          return response.json()
          .then(data => {
            const upgradeButton = (
              <Button
                color="secondary"
                variant="contained"
                size="small"
                style={{ marginTop: '-4px' }}
                disabled={isPerformingAuthAction}
                onClick={() => {
                  emitEvent(eventTypes.subscriptionPlansDialogOpenStatusBar);
                  onUpgradeClick();
                }}
              >Upgrade</Button>
            );
            const text = settings.values.oneTimePurchaseEnabled
              ? settings.messages.reader.diagramRestrictedOneTimePurchaseEnabled(upgradeButton)
              : settings.messages.reader.diagramRestrictedOneTimePurchaseDisabled(upgradeButton);
            this.setState({
              status: {
                running: false,
                progress: null,
                type: 'restricted',
                text,
              },
            });
          });
        } else {
          this.setState({
            status: {
              running: false,
              progress: null,
              type: 'error',
              text: `${settings.messages.reader.error}: ${response.statusText}`,
            },
          });
        }
      });
    });
  }

  openProcessDialog = () => {
    emitEvent(eventTypes.processingDialogOpen);
    this.setState({
      processDialog: {
        open: true
      }
    });
  };

  closeProcessFileDialog = (callback) => {
    this.setState({
      processDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      } else {
        emitEvent(eventTypes.processingDialogCancel);
      }
    });
  };

  openFileSizeExceededDialog = () => {
    this.setState({
      fileSizeExceededDialog: {
        open: true
      }
    });
  };

  closeFileSizeExceededDialog = (callback) => {
    this.setState({
      fileSizeExceededDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openStudyExport = () => {
    const activeTab = this.state.studyExportDialog.activeTab;
    this.setState({
      studyExportDialog: {
        ...this.state.studyExportDialog,
        open: true,
        activeTab: activeTab === 0 ? 1 : activeTab,  // open in Export as PGN by default unless some other Export type was selected before
      }
    }, () => {
      emitEvent(eventTypes.studyCreatorExportOpen);
    });
  }

  closeStudyExportDialog = (callback) => {
    this.setState({
      studyExportDialog: {
        ...this.state.studyExportDialog,
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openStudyEdit = () => {
    this.setState({
      studyExportDialog: {
        ...this.state.studyExportDialog,
        open: true,
        activeTab: 0,
      }
    }, () => {
      emitEvent(eventTypes.studyCreatorEditOpen);
    });
  }

  studyExportChangeTab = (value) => {
    this.setState({
      studyExportDialog: {
        ...this.state.studyExportDialog,
        activeTab: value,
      }
    });
  }

  studyExportChangePdfText = (textField, value) => {
    let newPdf = {
        ...this.state.studyExportDialog.pdf,
    }
    newPdf[textField] = value;
    this.setState({
      studyExportDialog: {
        ...this.state.studyExportDialog,
        pdf: newPdf,
      }
    });
  }

  sendFileToConversion = () => {
    const { uploadFile } = this.props;
    const { file, fileType, checksum } = this.state;

    emitEvent(eventTypes.documentConversionStart);
    uploadFile('conversions', file, checksum,
      (state, progress) => {
        switch (state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            this.setState({
              status: {
                running: false,
                text: settings.messages.reader.uploadingFilePaused,
                type: 'info',
                progress: progress,
              },
            });
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            this.setState({
              status: {
                running: true,
                text: settings.messages.reader.uploadingFile,
                type: 'info',
                progress: progress,
              }
            });
            break;
          default:
            this.setState({
              status: {
                running: false,
                text: `${settings.messages.reader.error}: ${state}`,
                type: 'error',
                progress: progress,
              }
            });
        }
      },
      (snapshot) => {
        return this.postConversion(fileType, checksum, file.name)
        .then(response => response.json())
        .then(conversion => {
          this.setState({
            status: {
              running: true,
              text: settings.messages.reader.convertingFile,
              type: 'info',
              progress: null,
            }
          });

          const localBackend = process.env.REACT_APP_API_URL.includes('localhost');
          // run this only with local backend because analyzing in local backend is a blocking call
          if (localBackend) {
            this.fetchTimeout = setTimeout(() => this.fetchConversion(), settings.values.conversionPoolInterval);
          }
          return this.patchConversion(conversion.id)
          .then(response => response.json())
          .then(data => {
            if (!localBackend) {
              // run this only with non local backends, where converting is not a blocking request and returns immediately
              this.fetchTimeout = setTimeout(() => this.fetchConversion(),
                settings.values.conversionPoolInterval);
            }
          });
        });
      },
      function(error) {
        console.error(error);
      }
    );
  }
  sendFileToAnalysis = () => {
    const { uploadFile } = this.props;
    const { file, checksum } = this.state;

    emitEvent(eventTypes.documentAnalysisStart);

    uploadFile('uploads', file, checksum,
      (state, progress) => {
        switch (state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            this.setState({
              status: {
                running: false,
                text: settings.messages.reader.uploadingFilePaused,
                type: 'info',
                progress: progress,
              },
            });
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            this.setState({
              status: {
                running: true,
                text: settings.messages.reader.uploadingFile,
                type: 'info',
                progress: progress,
              }
            });
            break;
          default:
            this.setState({
              status: {
                running: false,
                text: `${settings.messages.reader.error}: ${state}`,
                type: 'error',
                progress: progress,
              }
            });
        }
      },
      (snapshot) => {
        return this.postAnalysis(checksum, file.name)
        .then(response => response.json())
        .then(analysis => {
          this.setState({
            status: {
              running: true,
              text: settings.messages.reader.analyzingFile,
              type: 'info',
              progress: null,
            }
          });

          const localBackend = process.env.REACT_APP_API_URL.includes('localhost');
          // run this only with local backend because analyzing in local backend is a blocking call
          if (localBackend) {
            this.fetchTimeout = setTimeout(() => this.fetchAnalysis(), settings.values.analysisPoolInterval);
          }
          return this.patchAnalysis(analysis.id)
          .then(response => response.json())
          .then(data => {
            if (!localBackend) {
              // run this only with non local backends, where analyzing is not a blocking request and returns immediately
              this.fetchTimeout = setTimeout(() => this.fetchAnalysis(),
                settings.values.analysisPoolInterval);
            }
          });
        });
      },
      function(error) {
        console.error(error);
      }
    );
  }

  saveStudyToLocalStorage = () => {
    const key = settings.values.studyCreatorKey;
    window.localStorage.setItem(key, JSON.stringify(this.state.study));
  }

  studyClear = () => {
    this.setState({
      study: {
        diagrams: [],
      },
    }, () => { this.saveStudyToLocalStorage()});
  }

  studyAdd = () => {
    const { analysis, fen, comment, lastDiagram } = this.state;
    const { diagrams, ...rest } = this.state.study;
    this.setState({
      study: {
        ...rest,
        diagrams: [
          ...diagrams,
          {
            fen: fen || startingFen,
            comment,
            filename: analysis !== null ? analysis.title : null,
            pageNum: lastDiagram.pageNum,
          },
        ],
      }
    }, () => { this.saveStudyToLocalStorage()});
  }

  studyEdit = (newDiagrams) => {
    const { diagrams, ...rest } = this.state.study;
    this.setState({
      study: {
        ...rest,
        diagrams: newDiagrams,
      }
    }, () => { this.saveStudyToLocalStorage()});
  }

  studyCopyToClipboard = () => {
    const e = document.getElementById('study-export-textarea');
    e.select();
    try {
      document.execCommand('copy');
      e.focus();
      this.setState({
        studyCopiedToClipboard: true,
      });
    } catch (err) {
      alert('Unable to copy to clipboard, please select and copy manually.')
    }
  }

  handleDragBarMouseDown = (e) => {
    this.setState({
      isDragging: true,
    });
  }

  handleDragBarMouseMove = (e) => {
    const { theme } = this.props;
    const { analysisBoardWidth } = this.state;
    let newWidth = analysisBoardWidth - (theme.layoutDirection === 'ltr' ? 1 : -1) * e.movementX;
    newWidth = Math.min(700, Math.max(330, newWidth));
    this.setState({
      analysisBoardWidth: newWidth,
    });
  }

  handleDragBarMouseUp = (e) => {
    if (this.state.isDragging) {
      this.setState({
        isDragging: false,
      });
    }
  }

  handleDragBarTouchStart = (e) => {
    this.lastTouchX = e.targetTouches[0].clientX;
    this.setState({
      isDragging: true,
    });
  }

  handleDragBarTouchMove = (e) => {
    const { theme } = this.props;
    const { analysisBoardWidth } = this.state;
    const newClientX = e.targetTouches[0].clientX;
    const deltaX = newClientX - this.lastTouchX;
    let newWidth = analysisBoardWidth - (theme.layoutDirection === 'ltr' ? 1 : -1) * deltaX;
    newWidth = Math.min(700, Math.max(300, newWidth));
    this.lastTouchX = newClientX;
    this.setState({
      analysisBoardWidth: newWidth,
    });
  }

  handleDragBarTouchEnd = (e) => {
    if (this.state.isDragging) {
      this.setState({
        isDragging: false,
      });
    }
  }

  handleCommentChange = (comment) => {
    clearTimeout(this.commentSaveTimer);
    this.setState({
      comment,
    });

    this.commentSaveTimer = setTimeout(() => {
      this.patchDiagram();
    }, 750);
  }

  render() {
    const { classes } = this.props;
    const { isMobile } = this.props;
    const { user } = this.props;
    const { onTutorialsClick } = this.props;
    const { file, remoteFile, fen, pov, analysis, lastDiagram } = this.state;
    const { fullscreen } = this.state;
    const { analysisWindow } = this.state;
    const { study } = this.state;
    const { processDialog, fileSizeExceededDialog, status } = this.state;
    const { studyExportDialog } = this.state;
    const { editMode } = this.state;
    const { comment } = this.state;
    const { videosMode } = this.state;
    const { conversionDialog } = this.state;
    const { unsupportedFileFormatDialog } = this.state;

    const { isDragging, analysisBoardWidth } = this.state;
    const { studyCopiedToClipboard } = this.state;

    const { freeBook } = this.state;

    return (
      <React.Fragment>
        <div className={`${classes.root} ${fullscreen ? classes.rootFullscreen : ''}`}>
          { isDragging && (
            <div
              id="drag-ghost"
              className={classes.dragGhost}
              onMouseMove={this.handleDragBarMouseMove}
              onMouseUp={this.handleDragBarMouseUp}
            />
          )}
          { !(isMobile && (analysisWindow.open && (editMode.open || videosMode.open))) && (
            <div className={classes.controlsWrapper}>
              <TopBar
                status={status}
                analysisWindowOpen={analysisWindow.open}
                toggleAnalysisWindow={this.toggleAnalysis}
                fullscreen={fullscreen}
                toggleFullscreen={this.toggleFullscreen}
                accessStatusComponent={(settings.values.oneTimePurchaseEnabled && analysis.id !== null && !analysis.full_access)
                  ? (
                    <div>
                      <PurchaseButton user={user} checksum={analysis.checksum} text={`Get full access to this book ($${analysis.price/100})`} fullWidth={false} />
                    </div>
                  )
                  : null
                }
              />
            </div>
          )}
          <div className={classes.wrapper}>
            {/* display on mobile only if analysis, edit, videos windows are not open */}
            <div className={[classes.viewerWrapper, isMobile && (analysisWindow.open || editMode.open || videosMode.open) ? classes.hidden : classes.visible].join(' ')}>
              { file === null && remoteFile === null && (
                <div className={classes.tilesWrapper} style={{ paddingBottom: isMobile ? '5rem' : 0, height: `calc(100vh - ${fullscreen ? 6.4 : 9.9 + (isMobile ? 5 : 0)}rem`}}>
                  <div className={classes.tilesInner}>
                    <Tiles 
                      onTutorialsClick={onTutorialsClick} 
                      onGetBookClick={this.loadFreeBook}
                      bookLoading={freeBook.loading}
                      isMobile={isMobile}
                    />
                  </div>
                </div>
              )}
              <PDFViewer
                remoteFile={file === null ? remoteFile : null}
                handleFileChange={this.onFileChanged}
                handlePageClick={this.onPageClicked}
                fullscreen={fullscreen} />
            </div>
            <div className={[classes.videosWrapper, videosMode.open && !editMode.open ? classes.visible : classes.hidden].join(' ')}>
              <Videos data={videosMode.data} fullscreen={fullscreen} onClose={this.closeVideosMode} />
            </div>
            <div className={[classes.editModeWrapper, !videosMode.open && analysisWindow.open && editMode.open ? classes.visible : classes.hidden].join(' ')}>
              <EditMode
                fen={fen}
                onCancel={() => this.closeEditMode()}
                onApply={(fen) => this.handleApplyEdit(fen)}
                onSave={lastDiagram.pageNum === null ? null : (fen) => this.handleSaveEdit(fen)}
              />
            </div>
            <div className={`${!videosMode.open && analysisWindow.open && !editMode.open ? classes.visible : classes.hidden} ${classes.dragWrapper}`} style={{ display: 'flex' }}>
              { !isMobile && (
                <div
                  id="drag-bar"
                  className={classes.dragBar}
                  onMouseDown={this.handleDragBarMouseDown}
                  onTouchStart={this.handleDragBarTouchStart}
                  onTouchMove={this.handleDragBarTouchMove}
                  onTouchEnd={this.handleDragBarTouchEnd}
                  onTouchCancel={this.handleDragBarTouchEnd}
                />
              )}
              <div
                className={classes.analysisWrapper}
                style={{ width: isMobile ? '100%' : `${analysisBoardWidth}px`}}>
                <BoardAnalysis
                  isMobile={isMobile}
                  user={user}
                  fen={fen}
                  pov={pov}
                  comment={comment}
                  studySize={study !== null ? study.diagrams.length : null}
                  onStudyClear={this.studyClear}
                  onStudyAdd={this.studyAdd}
                  onStudyEdit={this.openStudyEdit}
                  onStudyExport={this.openStudyExport}
                  onOpenVideos={(data) => this.openVideosMode(data)}
                  onOpenEdit={(fen) => this.openEditMode()}
                  onCommentChange={lastDiagram.pageNum === null ? null : (comment) => this.handleCommentChange(comment)}
                  onPlayerChange={this.handlePlayerChange}
                  onOrientationChange={this.handleOrientationChange}
                  onRestorePosition={this.handleRestorePosition}
                  // NOTE: we don't use this now as we don't update pov on the server, we only fetch it onPovChange={this.handlePovChange}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmationDialog
          open={processDialog.open}
          title={settings.messages.requestAnalyzeDialog.title}
          content={settings.messages.requestAnalyzeDialog.text}
          okText={settings.messages.requestAnalyzeDialog.analyzeButton}
          disableOkButton={false}
          highlightOkButton
          onClose={this.closeProcessFileDialog}
          onCancelClick={this.closeProcessFileDialog}
          onOkClick={() => this.closeProcessFileDialog(this.sendFileToAnalysis)}
        />
        <ConfirmationDialog
          open={fileSizeExceededDialog.open}
          title={settings.messages.fileSizeExceededDialog.title}
          content={settings.messages.fileSizeExceededDialog.text}
          highlightOkButton
          onClose={this.closeFileSizeExceededDialog}
          onOkClick={this.closeFileSizeExceededDialog}
        />
        <ConfirmationDialog
          open={conversionDialog.open}
          title={settings.messages.conversionDialog.title}
          content={conversionDialog.content}
          okText={settings.messages.conversionDialog.convertButton}
          disableOkButton={false}
          highlightOkButton
          onClose={this.closeConversionDialog}
          onCancelClick={this.closeConversionDialog}
          onOkClick={() => this.closeConversionDialog(this.sendFileToConversion)}
        />
        <ConfirmationDialog
          open={unsupportedFileFormatDialog.open}
          title={settings.messages.unsupportedFileFormatDialog.title}
          content={unsupportedFileFormatDialog.content}
          highlightOkButton
          onClose={this.closeUnsupportedFileFormatDialog}
          onOkClick={this.closeUnsupportedFileFormatDialog}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={studyCopiedToClipboard}
          autoHideDuration={2000}
          onClose={() => { this.setState({ studyCopiedToClipboard: false })}}
          message="PGN copied!"
          severity="success"
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={freeBook.open}
          onClose={(e, reason) => { if (reason !== 'clickaway') { this.setState({ freeBook: { loading: false, open: false }})}}}
          severity="success"
        >
          <SnackbarContent 
            message="Want more?" 
            classes={{ action: classes.snackbarContentAction}}
            action={
              <div>
                <Button color="secondary" 
                  size="small"
                  href="https://saychess.substack.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained" 
                  style={{ textTransform: 'none'}}
                  onClick={() => emitEvent(eventTypes.sayChessSnackbarCTAClick)}
                >See Martin's Newsletter</Button>
                <IconButton 
                  size="small" 
                  style={{ marginLeft: 8, padding: 0, backgroundColor: 'rgb(49, 49, 49)', color: 'white' }}
                  onClick={() => { this.setState({ freeBook: { loading: false, open: false }})}}>
                    <CloseIcon size="small" style={{ fontSize: '1.25rem' }} />
                </IconButton>
              </div>}
            />
        </Snackbar>
        <ConfirmationDialog
          open={studyExportDialog.open}
          title="Your Study"
          content={
            <React.Fragment>
              <Tabs 
                value={studyExportDialog.activeTab} 
                onChange={(e, newValue) => { this.studyExportChangeTab(newValue) }} 
                aria-label="Export Study Options"
                variant="fullWidth"
              >
                <Tab className={classes.studyDialogTab} label={<span>Edit Study</span>} />
                <Tab className={classes.studyDialogTab} label={<span>Export<br />as PGN</span>} />
                <Tab className={classes.studyDialogTab} label={<span>Export<br />as PDF</span>} />
                <Tab className={classes.studyDialogTab} label={<span>Export<br />as TeX</span>} />
              </Tabs>
              <TabPanel value={studyExportDialog.activeTab} index={0}>
                <StudyEdit 
                  data={study.diagrams} 
                  onStudyEdit={this.studyEdit} 
                />
              </TabPanel>
              <TabPanel value={studyExportDialog.activeTab} index={1}>
                <span>
                  You can copy the PGN with the diagrams and export it to any tool that supports PGN import, e.g. <a href="https://lichess.org/study" target="_blank" rel="noreferrer noopener">Lichess Study</a> - paste it there as PGN while creating a study. This will create a new study with a single chapter for each of the positions you selected, including your comments.
                </span>
                <Button
                  color="secondary"
                  aria-label="copy to clipboard picture"
                  onClick={this.studyCopyToClipboard}
                  startIcon={<ClipboardIcon />}
                  variant="contained"
                  style={{ marginTop: 10 }}
                >Copy PGN to CLipboard</Button>
                <textarea
                  id="study-export-textarea"
                  readOnly
                  cols={65}
                  rows={20}
                  className={classes.studyDialogTextarea}
                  value={study.diagrams.map(diagram => studyDiagramToPGN(diagram)).join('\n')} />
              </TabPanel>
              <TabPanel value={studyExportDialog.activeTab} index={2}>
                <div>
                  <TextField
                    label="Left Header Text"
                    value={studyExportDialog.pdf.leftHeader}
                    helperText="Optional text in the top left corner of all pages"
                    onChange={(e) => { this.studyExportChangePdfText('leftHeader', e.target.value) }} />
                </div>
                <div>
                  <TextField
                    label="Right Header Text"
                    value={studyExportDialog.pdf.rightHeader}
                    helperText="Optional text in the top right corner of all pages"
                    onChange={(e) => { this.studyExportChangePdfText('rightHeader', e.target.value) }} />
                </div>
                <div>
                  <TextField
                    label="Left Footer Text"
                    value={studyExportDialog.pdf.leftFooter}
                    helperText="Optional text in the bottom left corner of all pages"
                    onChange={(e) => { this.studyExportChangePdfText('leftFooter', e.target.value) }} />
                </div>
                <div>
                  <TextField
                    value={studyExportDialog.pdf.enumerateFrom}
                    helperText="Enumerate diagrams from (leave 1 for default)"
                    InputProps={{
                      type: "number",
                    }}
                    style={{ marginTop: 8 }}
                    onChange={(e) => { this.studyExportChangePdfText('enumerateFrom', parseInt(e.target.value)) }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                  <Button
                    variant="contained"
                    color="secondary"
                    target="_blank"
                    style={{ marginTop: 36, textTransform: 'none' }}
                    href={fensToPdfUrl(
                      study.diagrams.map(diagram => diagram.fen),
                      study.diagrams.map(diagram => diagram.comment),
                      studyExportDialog.pdf.leftHeader,
                      studyExportDialog.pdf.rightHeader,
                      studyExportDialog.pdf.leftFooter,
                      studyExportDialog.pdf.enumerateFrom,
                    )}>Generate PDF</Button>
                </div>
              </TabPanel>
              <TabPanel value={studyExportDialog.activeTab} index={3}>
                <span>
                  This is for advanced users and allows you to download the raw TeX file that is used to generate the PDF export.
                </span>
                <div>
                  <TextField
                    label="Left Header Text"
                    value={studyExportDialog.pdf.leftHeader}
                    helperText="Optional text in the top left corner of all pages"
                    onChange={(e) => { this.studyExportChangePdfText('leftHeader', e.target.value) }} />
                </div>
                <div>
                  <TextField
                    label="Right Header Text"
                    value={studyExportDialog.pdf.rightHeader}
                    helperText="Optional text in the top right corner of all pages"
                    onChange={(e) => { this.studyExportChangePdfText('rightHeader', e.target.value) }} />
                </div>
                <div>
                  <TextField
                    label="Left Footer Text"
                    value={studyExportDialog.pdf.leftFooter}
                    helperText="Optional text in the bottom left corner of all pages"
                    onChange={(e) => { this.studyExportChangePdfText('leftFooter', e.target.value) }} />
                </div>
                <div>
                  <TextField
                    value={studyExportDialog.pdf.enumerateFrom}
                    helperText="Enumerate diagrams from (leave 1 for default)"
                    InputProps={{
                      type: "number",
                    }}
                    style={{ marginTop: 8 }}
                    onChange={(e) => { this.studyExportChangePdfText('enumerateFrom', parseInt(e.target.value)) }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                  <Button
                    variant="contained"
                    color="secondary"
                    target="_blank"
                    download="diagrams.tex"
                    href={(() => {
                      const fileContent = fensToLaTeX(
                        study.diagrams.map(diagram => diagram.fen),
                        studyExportDialog.pdf.leftHeader,
                        studyExportDialog.pdf.rightHeader,
                        studyExportDialog.pdf.leftFooter,
                        studyExportDialog.pdf.enumerateFrom,
                      );
                      const myFile = new Blob([fileContent], {type: 'application/x-tex'});
                      const windowURL = window.URL || window.webkitURL;
                      return windowURL.createObjectURL(myFile);
                    })()}
                    style={{ marginTop: 36, textTransform: 'none' }}
                    >Download TeX file</Button>
                </div>
              </TabPanel>
            </React.Fragment>
          }
          cancelText="close"
          onClose={this.closeStudyExportDialog}
          onCancelClick={this.closeStudyExportDialog}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Reader));
