import React from 'react';

import Button from '@material-ui/core/Button';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, useTheme } from '@material-ui/core/styles';

import appstore from '../../assets/appstore.png';
import playstore from '../../assets/playstore.png';
import settings from '../../settings';
import { eventTypes, emitEvent } from '../../events';

const styles = (theme) => ({
  root: {

    fontSize: '1.124rem',
    marginTop: 32,

    backgroundSize: 32,
    marginLeft: 2,
    marginRight: 2,
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
    transitionDuration: '.15s',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '12px center',
    backgroundColor: '#000 !important',
    fontSize: 16,
    color: '#fff !important',
    height: 48,
    paddingLeft: 30,
    lineHeight: 48,
    borderRadius: 10,
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.90,
    }
  },
  mobile: {
    width: 190,
    height: 63,
    backgroundSize: '95%',
    backgroundPosition: 'center',
  },
  ios: {
    backgroundImage: `url(${appstore})`,
  },
  android: {
    backgroundImage: `url(${playstore})`,
  },
});

const MobileButton = ({ classes, onClick, store, url }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('xs'));
  const storeClass = store === 'android' ? classes.android : classes.ios;
  return (
    <Button 
      onClick={onClick}
      href={url} 
      fullWidth={!desktop} 
      className={[classes.root, classes.mobile, storeClass]}
    />
  );
};

const MobileButtonWithStyles = withStyles(styles)(MobileButton);

export const AndroidButton = () => (
  <MobileButtonWithStyles 
    store="android" 
    onClick={() => {emitEvent(eventTypes.androidCTAClick)}}
    url={settings.values.playStoreUrl}
  />
);
export const AppleButton = () => (
  <MobileButtonWithStyles 
    store="apple" 
    onClick={() => {emitEvent(eventTypes.iosCTAClick)}}
    url={settings.values.appStoreUrl}
  />
);