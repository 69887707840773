// React
import React, { Component } from 'react';

// Firebase
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/performance';
import 'firebase/storage';

import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
// Custom
import settings from '../settings';

import utm from '../utm';
import getRefId from '../ref_ids';
import LaunchScreen from '../layout/LaunchScreen/LaunchScreen';
import App from '../App/App';
import Landing from '../Landing/Landing';

import { identifyUser } from '../events';

if (firebase.apps.length === 0) {
  firebase.initializeApp(settings.credentials.firebase);
}

firebase.analytics();
const auth = firebase.auth();

// eslint-disable-next-line no-unused-vars
const performance = firebase.performance();

auth.useDeviceLanguage();

class Site extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isAuthReady: false,
      isSignedIn: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.analytics.page();
    }
  }

  componentDidMount() {
    const { cookies } = this.props;

    const utms = utm(this.props.location.search);
    if (Object.keys(utms).length > 0) {
      cookies.set('chessvision-utms', JSON.stringify(utms), { path: '/' });
    }
    const ref_id = getRefId(this.props.location.search);
    if (ref_id !== null) {
      cookies.set('chessvision-ref_id', ref_id, { path: '/' });
    }

    this._isMounted = true;

    this.removeAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        identifyUser(user);
      }
      if (this._isMounted) {
        this.setState({
          isAuthReady: true,
          isSignedIn: !!user,
          user
        });
      }
    });
  }

  sendUtmData = (user, eventType) => {
    const { cookies } = this.props;
    const utms = cookies.get('chessvision-utms');
    if (utms !== undefined) {
      const data = {
        event: eventType,
        utms,
      };

      user.getIdToken().then(authToken => {
        const url = `${process.env.REACT_APP_API_URL}/utms/`;
        return fetch(url, {
          method: 'POST',
          headers: new Headers({
           'Authorization': authToken,
           'Accept': 'application/json',
           'Content-Type': 'application/json',
          }),
          body: JSON.stringify(data)
        })
      });
    }
  }

  sendRefData = (user) => {
    const { cookies } = this.props;
    const ref_id = cookies.get('chessvision-ref_id');
    if (ref_id !== undefined) {
      const data = {
        ref_id,
      };

      user.getIdToken().then(authToken => {
        const url = `${process.env.REACT_APP_API_URL}/ref_ids/`;
        return fetch(url, {
          method: 'POST',
          headers: new Headers({
           'Authorization': authToken,
           'Accept': 'application/json',
           'Content-Type': 'application/json',
          }),
          body: JSON.stringify(data)
        })
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    this.removeAuthObserver();
  }

  render() {
    const { cookies } = this.props;

    const {
      isAuthReady,
      isSignedIn,
      user,
    } = this.state;

    return (
      <>
        { !isAuthReady && (
          <LaunchScreen />
        )}
        { isAuthReady && isSignedIn && (
          <App user={user} auth={auth} />
        )}
        { isAuthReady && !isSignedIn && (
          <Landing
            cookies={cookies}
            auth={auth}
            onNewAccountCreated={(user) => {
              this.sendUtmData(user, 'registered');
              this.sendRefData(user);
            }}
          />
        )}
      </>
    );
  }
}

export default withRouter(withCookies(Site));
