import React, { Component} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';

import { ReactComponent as ChessableLogo } from '../../assets/chessable_logo.svg';
import { ReactComponent as ChessableLogoSmall } from '../../assets/chessable_logo_small.svg';

import { eventTypes, emitEvent } from '../../events';
import settings from '../../settings';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -8,
    top: 5,
  },
  colorPrimary: {
    color: '#fff',
    backgroundColor: '#43bf82',
  },
  colorSecondary: {
    color: '#fff',
    backgroundColor: '#ccc',
  }
}))(Badge);


class RelatedChessableCourses extends Component {

  state = {
    courses: undefined,
  };

  componentDidMount() {
    const { fen } = this.props;
    this.fetchRelatedCourses(fen);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fen !== this.props.fen) {
      this.fetchRelatedCourses(nextProps.fen);
    }
  }

  fetchRelatedCourses = (fen) => {
    this.setState({
      courses: undefined,
    });
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${settings.rootApiUrl}/chessable_courses?fen=${fen.split('_', 2).join('_')}`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': authToken,
       })
      });
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.json())
    })
    .then(data => {
      this.setState({
        courses: data,
      }, () => {
        if (data['num_courses'] > 0) {
          emitEvent(eventTypes.chessableCoursesShow, { fen });
        }
      });
    })
    .catch(e => {
      this.setState({
        courses: { num_courses: 0, url: null },
      });
    })
  }

  renderButton() {
    const { courses } = this.state;
    const { fen, size } = this.props;
    if (courses === undefined) {
      return (
        <CircularProgress size={16} color="primary" />
      )
    }
    const numCourses = courses['num_courses'];

    if (numCourses === 0) {
      return '';
    }

    const disabled = numCourses === 0;

    return (
      <Tooltip title="Chessable courses with this position">
        <IconButton
          target="_blank"
          href={`${courses['courses_url']}?utm_source=chessvision-ai&utm_medium=partner&utm_campaign=reader`}
          onClick={() => { emitEvent(eventTypes.chessableCoursesClick, { fen }); }}
          disableRipple
          disabled={disabled}
          style={{ backgroundColor: 'transparent', padding: 0 }}
        >
          <StyledBadge badgeContent={numCourses} max={10} showZero color={disabled ? 'secondary' : 'primary'}>
            { size === 'small'
              ? (
                <ChessableLogoSmall style={{ fill: disabled ? '#ccc' : '#034fdf', height: 24, width: 24 }} />
              )
              : (
                <ChessableLogo style={{ fill: disabled ? '#ccc' : '#034fdf', width: 64 }} />
              )
            }
          </StyledBadge>
        </IconButton>
      </Tooltip>
    );
  }

  render() {
    const { size } = this.props;
    const isSmall = size === 'small';
    const style = {
      minWidth: isSmall ? 24 : 50,
      textAlign: 'center',
      marginRight: isSmall ? '0.75rem' : '1rem',
    };
    return (
      <div style={style}>
        {this.renderButton()}
      </div>
    );
  }
}

export default RelatedChessableCourses;
